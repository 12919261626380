import { useEffect, useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import CarDetailsDuck from '../redux/carDetails/carDetails.duck';
import FinanceWidgetDuck from '../redux/financeWidget/financeWidget.duck';
import UIDuck from '../redux/commonDucks/ui.duck';
import FilterDuck from '../redux/filters/filter.duck';
import {
    filterOutCashOnlyVehicles,
    formatFinanceQuotePostRequestData,
    getAllVehicleMotorizations,
    transformFinancePriceListRequestData,
} from '@utils/FinanceWidget.utils';
import FinancePricesService from '../services/financePrices/financePrices.service';
import {
    calculateCashPrices,
    calculateCatalogPrices,
    calculateDefaultFinancePrices,
    calculateFinancePrices,
    hasFinanceVariants,
} from '@utils/Price.utils';
import { IOfferItem } from '../interfaces/IOfferItem';
import { ITrimItem } from '../redux/trimSelector/trimSelector.duck.interface';
import { useFeatureSwitchEnabled } from './useFeatureSwitchEnabled';
import { FEATURES_LIST } from '../context/featureSwitchApp';
import { findDefaultConfiguration } from '@utils/Offers.utils';
import { PaymentJourneyTypes } from '../partExchange/interfaces/Default';
import useCustomRouter from '@hooks/useCustomRouter';
import routes from '../constants/routes';
import { useFinanceWidgetDuck } from '@hooks/useFinanceWidgetDuck';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import { useConfiguratorDuck } from '@hooks/useConfiguratorDuck';
import { IVehicleTransformed } from '../services/stock/models/stockItems.service.model';
import { useCarStockJourneyCheck } from './useCarStockJourneyCheck';
import { TBudgetType } from 'src/interfaces/IFilters';
import { Redux } from 'src/redux/redux.interface';
import FinanceCalculatorService from 'src/services/financeQuote/financePrices.service';
import { IFinanceQuote } from 'src/services/calculateSummary/calculateSummary.types';
import { localeToCurrency } from '@utils/localeToCurrency';
import { LANGUAGE } from 'src/services';
import { isArray } from 'lodash';
import Routes from '../constants/routes';
import { LEASYS_PROVIDER_TYPE } from '../constants/main';

export const useFinancePricesList = (onPricesListUpdate?: () => void) => {
    const dispatch = useDispatch();
    const { financeQuote, financeWidgetParameters, financeWidgetOutput } = useFinanceWidgetDuck();
    const { configuratorState } = useConfiguratorDuck();
    const { allCarDetails, carDetails, engineSlugs, currentDeal } = useCarDetailsDuck();
    const { isStockJourney } = useCarStockJourneyCheck();

    useEffect(() => {
        const hasMonthlyPrice = hasFinanceVariants(carDetails?.prices);
        if (
            financeWidgetParameters &&
            hasMonthlyPrice &&
            !isStockJourney &&
            currentDeal?.extraFields?.providerType !== LEASYS_PROVIDER_TYPE
        ) {
            dispatch(UIDuck.setLoading(1));
            const allMotorizations = getAllVehicleMotorizations(carDetails, allCarDetails, engineSlugs);
            const financeValidMotorizations = filterOutCashOnlyVehicles(allMotorizations);
            const requestData = transformFinancePriceListRequestData(
                carDetails,
                financeWidgetParameters,
                configuratorState,
                financeValidMotorizations
            );
            let newFinanceQuote: IFinanceQuote;
            const updateFinanceQuote = async () => {
                try {
                    const queryParams = new URLSearchParams(window.location.search);
                    const financeQuoteIdFromURL = queryParams.get('financeQuoteId');
                    const { data } = await FinanceCalculatorService.updateFinanceQuote(
                        formatFinanceQuotePostRequestData(
                            carDetails,
                            financeWidgetParameters,
                            localeToCurrency(LANGUAGE)
                        ),
                        financeQuoteIdFromURL ?? currentDeal.financeQuoteId
                    );
                    newFinanceQuote = data;
                } catch (error: any) {
                    console.error('Finance quote update error', error);
                }

                dispatch(FinanceWidgetDuck.setIsPricesListLoading(true));
                FinancePricesService.getFinancePricesList(requestData)
                    .then(({ data }) => {
                        batch(() => {
                            dispatch(FinanceWidgetDuck.setFinancePricesList(data));
                            dispatch(
                                FinanceWidgetDuck.setFinanceQuote({
                                    ...newFinanceQuote,
                                })
                            );
                            dispatch(FinanceWidgetDuck.setIsPricesListLoading(false));
                            dispatch(UIDuck.setLoading(-4));
                        });
                        onPricesListUpdate?.();
                    })
                    .catch((e) => {
                        dispatch(UIDuck.setLoading(-4));
                        console.error('Finance prices list error', e);
                    });
            };
            if (financeQuote?.token) {
                updateFinanceQuote();
            } else {
                dispatch(FinanceWidgetDuck.setIsPricesListLoading(true));
                FinancePricesService.getFinancePricesList(requestData)
                    .then(({ data }) => {
                        batch(() => {
                            dispatch(FinanceWidgetDuck.setFinancePricesList(data));
                            const financeData = data.find((data) => data.code === carDetails.externalId);
                            dispatch(
                                FinanceWidgetDuck.setFinanceQuote({
                                    ...financeQuote,
                                    monthlyPayment: financeData.price,
                                    apr: financeData.apr,
                                    tin: financeData.tin,
                                    deposit: requestData.parameters.depositAmount,
                                    duration: financeWidgetParameters.duration as number,
                                    annualMileage: financeWidgetParameters.annualMileage,
                                    numberOfMonthlyPayments: financeWidgetParameters.duration as number,
                                    primaryText: financeData.promotionalText,
                                    finalPayment: financeData.finalPayment,
                                    financeGatewayParameters: financeWidgetParameters,
                                })
                            );
                            dispatch(FinanceWidgetDuck.setIsPricesListLoading(false));
                            dispatch(UIDuck.setLoading(-4));
                        });
                        onPricesListUpdate?.();
                    })
                    .catch((e) => {
                        dispatch(UIDuck.setLoading(-4));
                        console.error('Finance prices list error', e);
                    });
            }
        } else if (financeQuote && !financeQuote.isPersonalized) {
            batch(() => {
                dispatch(FinanceWidgetDuck.setFinancePricesList(null));
                dispatch(UIDuck.setLoading(-4));
                onPricesListUpdate?.();
            });
        } else {
            dispatch(UIDuck.setLoading(-4));
        }
    }, [
        ...Object.values(financeWidgetOutput || {}),
        carDetails.externalId || carDetails.vehicleId || carDetails._id || carDetails.id,
    ]);
};

export const usePrices = (
    item?: IOfferItem | ITrimItem | IVehicleTransformed,
    onlyDefaultFinancePrices: boolean = false,
    dealerDiscount: number = null,
    ignoreBenefits: boolean = true,
    ignoreFees: boolean = false,
    paramPaymentJourney: TBudgetType | undefined = undefined
) => {
    const router = useCustomRouter();
    const currentCarDetails = useSelector(CarDetailsDuck.getCurrentCarDetails);
    const currentDeal = useSelector(CarDetailsDuck.getCurrentDeal);
    const carDetails = item ?? currentCarDetails;

    const globalPaymentJourney = useSelector((state) => FilterDuck.getPaymentJourneyType(state));
    const paymentJourney = paramPaymentJourney ?? globalPaymentJourney;

    const filters = useSelector((state: Redux) => FilterDuck.getFilters(state));
    const selectedFiltersCount = useMemo(() => filters.filter((filter) => !!filter.value).length, [filters]);

    const hasSelectedBudgetType =
        !router.pathname?.includes(routes.CAR) &&
        !router.pathname?.includes(routes.SUMMARY) &&
        !router.pathname?.includes(routes.DEALER) &&
        currentDeal?.extraFields?.hasSelectedPaymentJourney;
    const isBenefitsEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_BENEFITS_MODE_ENABLED);
    const isDealerFeesFsEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_DEALER_FEES_ENABLED);

    if (!ignoreBenefits && selectedFiltersCount === 0) {
        const benefitsConfiguration = isBenefitsEnabled
            ? findDefaultConfiguration(carDetails.offers, paymentJourney as PaymentJourneyTypes)
            : null;

        if (benefitsConfiguration && benefitsConfiguration.prices) {
            carDetails.prices = benefitsConfiguration.prices;
        }
        if (benefitsConfiguration && benefitsConfiguration?.pricesV2) {
            carDetails.pricesV2 = benefitsConfiguration.pricesV2;
        }

        if (benefitsConfiguration && !benefitsConfiguration?.pricesV2) {
            carDetails.pricesV2 = null;
            carDetails.extraFields.pricesV2 = null;
        }
    }

    const financeQuote = useSelector(FinanceWidgetDuck.getFinanceQuote);
    const pricesList = useSelector(FinanceWidgetDuck.getFinancePricesList) ?? [];

    const isSOLEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_SOL_ENABLED);

    // if options forced in cash, use straight up only finance quote as we don't need component prices / price list
    const optionsInCash = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_FORCE_OPTIONS_IN_CASH);

    const cashPrices = calculateCashPrices(
        carDetails,
        paymentJourney,
        isSOLEnabled,
        dealerDiscount,
        hasSelectedBudgetType,
        ignoreFees,
        isDealerFeesFsEnabled
    );

    const catalogPrices = calculateCatalogPrices(carDetails, isSOLEnabled);
    const defaultFinancePrices = calculateDefaultFinancePrices(carDetails, paymentJourney);

    if ((!onlyDefaultFinancePrices && financeQuote && pricesList) || (optionsInCash && financeQuote)) {
        return {
            cash: cashPrices,
            finance: calculateFinancePrices(
                carDetails,
                cashPrices,
                financeQuote,
                !router.pathname?.includes(Routes.CAR) ? [] : pricesList,
                paymentJourney
            ),
            financeDefault: defaultFinancePrices,
            catalog: catalogPrices,
        };
    }

    // promo code purpose
    if (!onlyDefaultFinancePrices && financeQuote) {
        return {
            cash: cashPrices,
            finance: {
                ...defaultFinancePrices,
                totalPrice: financeQuote.monthlyPayment,
                basePrice: financeQuote.monthlyPayment,
            },
            financeDefault: {
                ...defaultFinancePrices,
                totalPrice: financeQuote.monthlyPayment,
                basePrice: financeQuote.monthlyPayment,
            },
            catalog: catalogPrices,
        };
    }

    return {
        cash: cashPrices,
        finance: defaultFinancePrices,
        financeDefault: defaultFinancePrices,
        catalog: catalogPrices,
    };
};
